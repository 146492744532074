<template>
  <b-modal
    id="modal_atenciones_paciente"
    :visible="shallShowEmailComposeModal"
    title="Inter consultas"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
  >

    <b-row class="d-flex justify-content-center">
      <h4 class="text-uppercase font-weight-bold text-primary">
        {{ atencionData && atencionData.nombre_completo }}
      </h4>
      <b-button
        class="ml-4"
        size="sm"
        variant="success"
        @click="showAdd = !showAdd"
      >
        +
      </b-button>
    </b-row>
    <b-row class="d-flex justify-content-center">
      <strong><i>DNI: </i></strong>
      <p class="font-weight-bold font-italic font-weight-bold">
        {{ atencionData && atencionData.numero_documento }}
      </p>
    </b-row>
    <b-row
      v-if="showAdd"
      class="bg-light-success mb-1"
    >
      <b-col
        cols="12"
      >
        <!-- Input servicio -->
        <b-form-group
          label="Adicionar Servicios"
        >
          <v-select
            v-model="atencionData.servicios"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="nombre"
            :options="optionsTipos"
            :reduce="val => val.id"
            multiple
            placeholder="Seleccionar"
            @input="agregarServicios"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Servicios  -->
    <b-row>

      <!-- Citas -->
      <b-col
        md="4"
        class="border-right"
      >
        <b-media
          no-body
          class="mb-1"
        >
          <b-media-aside

            class="mr-2"
          >
            <b-avatar
              size="30"
              variant="light-info"
            >
              <feather-icon
                size="16"
                icon="CalendarIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h6 class="font-weight-bolder mb-0">
              Desde Cita
            </h6>
          </b-media-body>
        </b-media>
        <b-table-simple
          striped
          bordered
          borderless
          small
          responsive
        >
          <b-thead head-variant="dark">
            <b-tr>
              <b-th variant="secondary">
                N°
              </b-th>
              <b-th variant="primary">
                Servicio
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(item, index) of serviciosCita"
              :key="index"
            >
              <b-td>{{ index+1 }}</b-td>
              <b-td>
                {{ item.nombre }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>

      <!-- Triaje -->
      <b-col
        md="4"
      >
        <b-media
          no-body
          class="mb-1"
        >
          <b-media-aside

            class="mr-2"
          >
            <b-avatar
              size="30"
              variant="light-danger"
            >
              <feather-icon
                size="16"
                icon="HeartIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h6 class="font-weight-bolder mb-0">
              Desde Triaje
            </h6>
          </b-media-body>
        </b-media>

        <b-table-simple
          bordered
          striped
          borderless
          small
          responsive
        >
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>N°</b-th>
              <b-th>Servicio</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(item, index) of serviciosTriaje"
              :key="index"
            >
              <b-td>{{ index+1 }}</b-td>
              <b-td>{{ item.nombre }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>

      <!-- Interconsulta -->
      <b-col
        md="4"
        class="border-left"
      >
        <b-media
          no-body
          class="mb-1"
        >
          <b-media-aside

            class="mr-2"
          >
            <b-avatar
              size="30"
              variant="light-warning"
            >
              <feather-icon
                size="16"
                icon="ChevronsRightIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h6 class="font-weight-bolder mb-0">
              Para Interconsulta
            </h6>
          </b-media-body>
        </b-media>

        <b-table-simple
          bordered
          striped
          borderless
          small
          responsive
        >
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>N°</b-th>
              <b-th>Servicio</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(item, index) of serviciosInter"
              :key="index"
            >
              <b-td>{{ index+1 }}</b-td>
              <b-td>
                {{ item.nombre }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>

    </b-row>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="danger"
          size="sm"
          class="float-right"
          @click="closeWindow"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

/* eslint-disable vue/no-unused-components */
import {
BAvatar, BButton,
BCard, BCardText,
BCol,
BForm,
BFormGroup,
BFormInput,
BMedia,
BMediaAside,
BMediaBody,
BRow,
BTable,
BTableSimple,
BTbody,
BTd, BTh,
BThead,
BTr,
} from 'bootstrap-vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import {
nextTick,
ref, toRefs,
watch,
} from '@vue/composition-api'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BButton,
    BFormGroup,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BCardText,
    BMediaBody,
    BMediaAside,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BForm,
    BCard,
  },
  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-email-compose-modal',
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    editedItem: {
      type: Object,
      default() {
        return { }
      },
    },
  },
  data() {
    return {
      optionsTipos: [],
    }
  },
  created() {
    this.$http.get('/salud/tipos/servicios').then(response => {
      this.optionsTipos = response.data.data
    })
  },
  methods: {
    closeWindow() {
      this.$emit('update:shall-show-email-compose-modal', false)
      this.$bvModal.hide('modal_atenciones_paciente');
    }
  },
  setup(props, { emit }) {
    const { editedItem } = toRefs(props)
    // const { pacienteId } = toRefs(props)
    const serviciosCita = ref([])
    const serviciosTriaje = ref([])
    const serviciosInter = ref([])
    const dialog = ref(false)
    const showAdd = ref(false)

    const blankAtencionData = {
      id: null,
      paciente_id: null,
      servidor_publico: null,
      servicios: [],
      persona: {
        nombre_completo: null,
        numero_documento: null,
      },
    }

    const atencionData = ref(JSON.parse(JSON.stringify(blankAtencionData)))

    const resetAtencionData = () => {
      atencionData.value = JSON.parse(JSON.stringify(blankAtencionData))
    }
    const discardEmail = () => {
      // composeData.value = {}
      emit('update:shall-show-email-compose-modal', false)
    }

    const close = () => {
      emit('update:shall-show-email-compose-modal', false)
      dialog.value = false
      nextTick(() => {
        resetAtencionData()
      })
    }
    watch(dialog, () => {
      // eslint-disable-next-line no-unused-expressions
      dialog.value || close()
    })

    watch(editedItem, () => {
      atencionData.value = editedItem.value.editedItem;

      // Obtener los datos de la persona
      // personaReadOnly.value = editedItem.value.persona

      // Obtener los servicios de una cita
      // serviciosCita.value = editedItem.value.triaje.cita.servicios

      // Obtener los servicios de un triaje
      // serviciosTriaje.value = editedItem.value.triaje.servicios

      // Obtener los servicios de una atencion
      // serviciosInter.value = editedItem.value.servicios

      // const { atencion } = editedItem.value
      // // const datos = []
      // // // serviciosInter.value = atencion.map(item => {
      // // //   const { servicios } = item
      // // //   const ser = servicios.forEach((i, index) => {
      // // //     datos.push(i)
      // // //     console.log(i, index)
      // // //     return i
      // // //   })
      // // //   return ser
      // // // })
      // // // serviciosInter.value = datos

      // Id paciente
      // pacienteId.value = editedItem.value.paciente_id
    })

    const agregarServicios = () => {
      console.log('Agregando servicios al triaje ... - Alexander ')
      const { id } = atencionData.value
      axios.put(`atenciones/${id}/agregar-servicios`, atencionData.value).then(response => {
        console.log('Despues de actualizar ', response.data)
        serviciosInter.value = response.data.servicios

        // Debemos actualizar en el padre
        emit('actualizar-servicios', response.data.servicios)
      })
    }

    return {
      discardEmail,
      agregarServicios,
      serviciosCita,
      serviciosTriaje,
      serviciosInter,
      resetAtencionData,
      atencionData,
      close,
      showAdd,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
