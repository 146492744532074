<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filtrar
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Servicio</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roleFilter"
            label="nombre"
            :options="roleOptions"
            class="w-100"
            :reduce="val => val.id"
            @input="(val) => $emit('update:roleFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Fecha</label>
          <b-form-group>
            <flat-pickr
              :value="desdeHastaFilter"
              class="form-control"
              :config="{ mode: 'range', altInput: true, altFormat: 'd/m/Y', dateFormat: 'Y-m-d', defaultDate: new Date()}"
              @input="(val) => $emit('update:desdeHastaFilter', val)"
            />
          </b-form-group>
          <!-- <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="planFilter"
            :options="planOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:planFilter', val)"
          /> -->
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Estado</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="estadoFilter"
            :options="estadoOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:estadoFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    flatPickr,
    BFormGroup,
  },
  props: {
    roleFilter: {
      type: [Number, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    desdeHastaFilter: {
      type: [String, null],
      default: null,
    },
    estadoFilter: {
      type: [String, null],
      default: null,
    },
    estadoOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rangeDate: null,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
